<template>
  <b10-list-item
    :item="formattedItem"
    dense
  >
    <v-list-item-avatar>
      <slot
        name="avatar"
      >
        <v-icon>
          {{ formattedItem.avatarIcon }}
        </v-icon>
      </slot>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title
        class="text-wrap"
      >
        {{ formattedItem.title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ formattedItem.subtitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data () {
    return {}
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      const detalles = this.$online.loperacioncrm.detalles(item)
      item.title = item.loperacion_desc
      item.subtitle = detalles.subtitle
      item.avatarIcon = detalles.icon
      return item
    }
  },
}
</script>
